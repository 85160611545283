.service-details-section h4 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
}

@media(max-width:767px){
    .service-details-section h4 {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 20px;
    }
}