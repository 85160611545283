
// tp-page-title
.tp-page-title{
    position: relative;
    z-index: 1;
	background: $theme-primary-color;
	width: 250px;
	height: 250px;
	bottom: -125px;
	margin: 0 auto;
	padding: 20px;
	border-radius: 50%;
	z-index: -1;

	&:before {
		content: "";
		position: absolute;
		border-radius: 50%;
		z-index: -2;
		background-color: transparentize($theme-primary-color, .3);
		width: 110%;
		height: 110%;
		left: -5%;
		top: -5%;
		-webkit-animation: breadcrumb-pulse 1s infinite;
		animation: breadcrumb-pulse 1s infinite;
	}


	@-webkit-keyframes breadcrumb-pulse {
		from {
		  -webkit-transform: scale3d(1, 1, 1);
		  transform: scale3d(1, 1, 1);
		}
		50% {
		  -webkit-transform: scale3d(0.95, 0.95, 0.95);
		  transform: scale3d(0.95, 0.95, 0.95);
		}
		to {
		  -webkit-transform: scale3d(1, 1, 1);
		  transform: scale3d(1, 1, 1);
		}
	  }
	  
	  @keyframes breadcrumb-pulse {
		from {
		  -webkit-transform: scale3d(1, 1, 1);
		  transform: scale3d(1, 1, 1);
		}
		50% {
		  -webkit-transform: scale3d(0.95, 0.95, 0.95);
		  transform: scale3d(0.95, 0.95, 0.95);
		}
		to {
		  -webkit-transform: scale3d(1, 1, 1);
		  transform: scale3d(1, 1, 1);
		}
	  }


	@media(max-width: 767px) {
		min-height: 250px;
	}

	.tp-breadcumb-wrap {
		text-align: center;
		margin-top: 20px;

		h2{
			font-size: 22px;
			color: #fff;
			line-height: 30px;
			margin-bottom: 5px;
			font-weight: 900;
		}

		ol{
			padding-left: 0;
			margin-bottom: 0;
			li{
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-size: 16px;
				font-family: $heading-font;

				@media(max-width:767px){
					font-size: 18px;
				}

				&:after{
					content: "/";
					position: relative;
					left: 7px;
				}

				&:last-child{
					span{
						color: #cbd4fd;
					}
					&:after{
						display: none;
					}
				}

				a{
					color: #fff;
					font-size: 16px;
					transition: all .3s;

					&:hover{
						color: $text-color2;
					}
				}
			}
		}
	}
}

